:root {
  --ecstasy-color: #F6881A;
  --summerGreen-color: #90BF93;
  --supernova-color: #FBC70B;
  --goblin-color: #34733C;
  --white-color: #FFFFFF;
  --heavyMetal-color: #1D221D;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
}


.break-pagination {
  color: var(--heavyMetal-color);
}

.pagination-page-element {
  background-color: var(--heavyMetal-color);
  color: var(--white-color);
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

}

a:hover.pagination-page-element {
  background-color: var(--goblin-color);
  color: var(--white-color);
  transform: translateY(-2px);
  box-shadow: 0px 8px 15px 2px rgba(0, 0, 0, 0.25);
}

.pagination-page-element-link .pagination-page-element {
  background-color: var(--ecstasy-color);
}

.pagination-previous-button {
  background-color: var(--heavyMetal-color);
  color: var(--white-color);
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-next-button {
  background-color: var(--heavyMetal-color);
  color: var(--white-color);
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

li:hover.pagination-previous-button {
  background-color: var(--goblin-color);
  color: var(--white-color);
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-2px);
  box-shadow: 0px 8px 15px 2px rgba(0, 0, 0, 0.25);
}

li:hover.pagination-next-button {
  background-color: var(--goblin-color);
  color: var(--white-color);
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-2px);
  box-shadow: 0px 8px 15px 2px rgba(0, 0, 0, 0.25);
}

.form-container {
  background-color: rgba(245, 245, 245, 0.4);
  position: absolute;
  top: 45%;
  height: 300px;
  width: 32%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em
}

.company-label {
  font-size: 18pt;
  text-align: center, ;
  color: #34733c;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 25%;
  margin-right: 25%;
}

.domain-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  aligntems: center;

}

.next-btn-domain {
  margin-top: 40px;
  background-color: #f6881a !important;
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-Items: center !important;
  box-shadow: 10px 10px 12px -8px rgba(0, 0, 0, 0.59);
  -webkit-box-shadow: 10px 10px 12px -8px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 10px 10px 12px -8px rgba(0, 0, 0, 0.59);
}